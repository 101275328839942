<template>
  <div class="step-wrapper">
    <div class="description">
      Aktuell Schritt:
    </div>
    <div class="step">
      {{ currentStep }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'CurrentStepDisplay',
  props: {
    currentStep: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped>
.step-wrapper {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: var(--goe-spacing-2);
  margin-left: auto;
  margin-right: var(--goe-spacing-0);
  width: max-content;
  padding: var(--goe-spacing-0) var(--goe-spacing-2);
}

.description {
  font-size: var(--goe-fontSize-small);
  color: var(--goe-fontColor-light);
  margin-bottom: 0.2em;
  margin-top: auto;
}

.step {
  color: var(--goe-fontColor-highlight);
  font-weight: var(--goe-fontWeight-bold);
  font-size: var(--goe-fontSize-normal);
}
</style>
