<template>
  <div>
    <v-data-table
      :headers="documentTableHeaders"
      :items="products"
      :items-per-page="-1"
      no-data-text="Produkte wurden noch nicht geladen."
      dense
      class="elevation-1"
      hide-default-footer
    >
      <template v-slot:[`item.dateOfDeactivation`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div
              :class="productStatusToStatusClassMappings[item.productStatus]"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <span>
            {{
              mapProductStatusToStatusMessage(
                item.productStatus,
                item.dateOfActivation,
                item.dateOfDeactivation
              )
            }}
          </span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.description`]="{ item }">
        <div v-if="item.description === null || item.description === ''">
          -
        </div>
        <div v-else>
          {{ item.description }}
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import TimeUtility from '@/util/TimeUtility.js';
import { LocalDate } from '@js-joda/core';

export default {
  name: 'AllOrderedProductsTable',
  props: {
    // BasicProductInformationDto
    allProducts: { type: Object, required: false, default: null }
  },
  data: () => ({
    documentTableHeaders: [
      { text: 'Status', value: 'dateOfDeactivation', width: '90px' },
      {
        text: 'Name',
        align: 'start',
        value: 'name'
      },
      { text: 'Produkttyp', value: 'productType', width: '150px' },
      { text: 'Beschreibung', value: 'description' }
    ],
    productStatusToStatusClassMappings: {
      ACTIVE: 'status-green',
      DEACTIVATION_PLANED: 'status-yellow',
      DEACTIVATED: 'status-red',
      INACTIVE: 'status-white',
      UNKNOWN: ''
    }
  }),
  computed: {
    products: function () {
      if (this.allProducts) {
        const contractProducts = this.allProducts.contractProducts.map(
          (product, index) => this.mapDtoToLocalRepresentation(index, product, '-')
        );
        const productsOfVoiceTariff =
          this.allProducts.productsOfVoiceTariff.map((product, index) =>
            this.mapDtoToLocalRepresentation(index, product, 'VoIP')
          );
        const productsOfDataTariff = this.allProducts.productsOfDataTariff.map(
          (product, index) => this.mapDtoToLocalRepresentation(index, product, 'Internet')
        );
        return contractProducts.concat(
          productsOfVoiceTariff,
          productsOfDataTariff
        );
      } else {
        return undefined;
      }
    }
  },
  methods: {
    formatLocalDate: (date) => {
      return TimeUtility.formatLocalDate(date);
    },
    mapDtoToLocalRepresentation(index, product, productTypeLabel) {
      return { ...product, id: index + '' + product.id, productType: productTypeLabel };
    },
    mapProductStatusToStatusMessage(
      productStatus,
      activationDate,
      deactivationDate
    ) {
      if (productStatus === 'ACTIVE') {
        return `Aktiv seit ${this.formatLocalDate(activationDate)}`;
      } else if (productStatus === 'DEACTIVATION_PLANED') {
        return `Wird am ${this.formatLocalDate(deactivationDate)} deaktiviert`;
      } else if (productStatus === 'DEACTIVATED') {
        return `Am ${this.formatLocalDate(deactivationDate)} deaktiviert`;
      } else if (productStatus === 'INACTIVE') {
        if (activationDate) {
          return `Aktiv ab ${this.formatLocalDate(activationDate)}`;
        } else {
          return 'Inaktiv';
        }
      } else {
        return '';
      }
    },
    dateIsInTheFuture: (date) => {
      return LocalDate.parse(date).isAfter(LocalDate.now());
    }
  }
};
</script>

<style scoped>
.status-white {
  width: 21px;
  height: 21px;
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 0 0 4px 1px #646464;
}

.status-green {
  width: 21px;
  height: 21px;
  background-color: #80ed99;
  border-radius: 50%;
  box-shadow: 0 0 4px 1px #80ed99;
}
.status-yellow {
  width: 21px;
  height: 21px;
  background-color: #d2e622;
  border-radius: 50%;
  box-shadow: 0 0 4px 1px #d2e622;
}
.status-red {
  width: 21px;
  height: 21px;
  background-color: #ff6b6b;
  border-radius: 50%;
  box-shadow: 0 0 4px 1px #ff6b6b;
}
</style>
