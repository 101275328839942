<template>
  <div>
    <div class="input-row-max-content-left margin-bottom-1">
      <v-text-field
        v-model="currentState.zipCode"
        label="Postleitzahl"
        persistent-placeholder
        dense
        :disabled="disabled"
        @input="
          {
            updateState('zipCode', $event);
          }
        "
        @keydown.enter="$emit('confirmed')"
      />

      <v-text-field
        v-model="currentState.city"
        label="Ort"
        hint="Ort auswählen"
        :disabled="disabled"
        persistent-placeholder
        dense
        @input="
          {
            updateState('city', $event);
          }
        "
        @keydown.enter="$emit('confirmed')"
      />
    </div>
    <div class="input-row-adress-data">
      <v-text-field
        v-model="currentState.street"
        label="Straße"
        hint="Straße auswählen"
        :disabled="disabled"
        persistent-placeholder
        dense
        @input="
          {
            updateState('street', $event);
          }
        "
        @keydown.enter="$emit('confirmed')"
      />

      <v-text-field
        v-model="currentState.houseNumber"
        label="Hausnummer"
        :disabled="disabled"
        persistent-placeholder
        dense
        @input="
          {
            updateState('houseNumber', $event);
          }
        "
        @keydown.enter="$emit('confirmed')"
      />

      <v-text-field
        v-model="currentState.houseNumberSupplement"
        label="Hausnummernzusatz"
        :disabled="disabled"
        persistent-placeholder
        dense
        @input="
          {
            updateState('houseNumberSupplement', $event);
          }
        "
        @keydown.enter="$emit('confirmed')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContractAddressSelection',
  components: {},
  props: {
    state: {
      type: Object,
      required: true,
      default() {
        return {
          zipCode: null,
          city: null,
          street: null,
          houseNumber: null,
          houseNumberSupplement: null
        };
      }
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    validationErrors: null,

    loading: false,

    addressIntern: {
      zipCode: '',
      city: '',
      street: '',
      houseNumber: ''
    }
  }),
  computed: {
    currentState() {
      return { ...this.state };
    }
  },
  watch: {},
  created() {},
  methods: {
    buildDefaultAddress() {
      return {
        zipCode: null,
        city: null,
        street: null,
        houseNumber: null,
        houseNumberSupplement: null
      };
    },
    updateState: function (varName, varValue) {
      this.currentState[varName] = varValue;
      this.$emit('stateChanged', this.currentState);
      // this.validateInputs();
    }
  }
};
</script>
<style lang="scss" scoped>
.input-row-adress-data {
  display: grid;
  grid-template-columns: auto max-content max-content;
  grid-gap: var(--goe-spacing-1);
}
</style>
