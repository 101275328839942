<template>
  <div class="group">
    <div>{{ label }}</div>
    <div>
      <div class="input-row">
        <v-autocomplete
          :value="value"
          :items="carrierList"
          :item-text="
            (item) => {
              return `${item.name} — (${item.routingId})`;
            }
          "
          placeholder="Carrier auswählen..."
          persistent-placeholder
          return-object
          clearable
          :disabled="disabled"
          :loading="carrierListLoading"
          :label="selectionLabel"
          :error="errorMessage !== null"
          :error-messages="errorMessage"
          @input="$emit('input', $event)"
        />
        <div>
          <div class="label">
            Vertragspartner
          </div>
          <div>
            <template v-if="value !== null">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">
                    {{
                      value.contractExists
                        ? 'mdi-check-circle'
                        : 'mdi-close-circle'
                    }}
                  </v-icon>
                </template>
                <span>
                  {{
                    value.contractExists
                      ? 'Vertragspartner'
                      : 'Kein Vertragspartner'
                  }}
                </span>
              </v-tooltip>
            </template>
            <template v-else>
              -
            </template>
          </div>
        </div>
      </div>
      <div class="input-row">
        <div>
          <div class="label">
            Portierungskennung
          </div>
          <div>
            {{ value !== null ? value.routingId : '-' }}
          </div>
        </div>
        <div>
          <div class="label">
            ITU Carrier-Code
          </div>
          <div>
            {{ value !== null ? value.carrierCode : '-' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { HTTP } from '@/main/httpClient.js';

export default {
  name: 'CarrierSelection',
  props: {
    value: {
      type: Object,
      default: null,
      required: false
    },
    label: {
      type: String,
      default: 'Carrier-Auswahl',
      required: false
    },
    selectionLabel: {
      type: String,
      default: 'Auswahl *',
      required: false
    },
    errorMessage: {
      type: String,
      default: null,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data: () => ({
    carrierList: [],
    carrierListLoading: false
  }),
  mounted: function () {
    this.loadCarrierList();
  },
  methods: {
    loadCarrierList: function () {
      this.carrierListLoading = true;
      HTTP.get('/porting/carrierData')
        .then((resp) => {
          this.carrierList = resp.data.map((carrier) => ({
            routingId: carrier.routingId,
            carrierId: carrier.carrierId,
            carrierCode: carrier.carrierCode,
            name: carrier.name,
            contractExists: carrier.contractExists
          }));
        })
        .finally(() => {
          this.carrierListLoading = false;
        });
    }
  }
};
</script>
<style scoped></style>
