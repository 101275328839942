<template>
  <div class="input-row">
    <NameDisplay :addressDto="addressDto" />
    <AddressDisplay :addressDto="addressDto" />
    <ContactInformationDisplay :addressDto="addressDto" />
    <DateDisplay
      label="Geburtsdatum"
      :date="addressDto && addressDto.birthDate ? addressDto.birthDate : null"
    />
  </div>
</template>

<script>
import NameDisplay from '@/components/dataDisplays/NameDisplay.vue';
import DateDisplay from '@/components/dataDisplays/DateDisplay.vue';
import AddressDisplay from '@/components/dataDisplays/AddressDisplay.vue';
import ContactInformationDisplay from '@/components/dataDisplays/ContactInformationDisplay.vue';
export default {
  components: {
    NameDisplay,
    DateDisplay,
    AddressDisplay,
    ContactInformationDisplay
  },
  props: {
    addressDto: {
      type: Object,
      required: false,
      default: null
    }
  }
};
</script>

<style></style>
