<template>
  <DisplayContainer :label="title">
    <div class="input-row">
      <DataDisplay label="Anrede, Vor-/Nachname">
        <div>
          {{ parseSalutation ? getFormattedSalutation() : data.salutation }}
          {{ data.firstName }}
          {{ data.lastName }}
        </div>
      </DataDisplay>
      <DataDisplay label="Kundennummer">
        <div class="input-row-max-content-left">
          <div>
            <TextLink
              linkType="otrs"
              :linkObject="
                isNaN(data.customerId)
                  ? data.customerId
                  : data.customerId.toString()
              "
            >
              {{
                data.customerId
                  ? data.customerId
                  : customerId
                    ? customerId
                    : '-'
              }}
            </TextLink>
          </div>
        </div>
      </DataDisplay>
      <DataDisplay v-show="!small" label="Geburtsdatum">
        <div v-if="data.birthDate">
          {{
            new Date(data.birthDate).toLocaleDateString('de-DE', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            })
          }}
        </div>
      </DataDisplay>
      <DataDisplay v-show="!small" label="Firma">
        <div v-if="data.company">
          {{ data.company }}
        </div>
      </DataDisplay>

      <DataDisplay label="Telefonnummer">
        <div v-if="data.phone">
          {{ data.phone }}
        </div>
      </DataDisplay>
      <DataDisplay v-if="!small" label="Handynummer">
        <div v-if="data.mobil">
          {{ data.mobil }}
        </div>
        <div else-if="data.cellPhoneNumber">
          {{ data.cellPhoneNumber }}
        </div>
      </DataDisplay>
      <DataDisplay v-else label="E-Mail">
        <div v-if="data.email">
          {{ data.email }}
        </div>
      </DataDisplay>

      <DataDisplay v-show="!small" label="Adresse">
        <div>
          {{ data.street }} {{ data.houseNumber
          }}{{ data.houseNumberSupplement }}
        </div>
        <div>{{ data.zipCode }} {{ data.city }}</div>
      </DataDisplay>

      <DataDisplay v-show="!small" label="E-Mail">
        <div v-if="data.email" class="space">
          {{ data.email }}
        </div>
      </DataDisplay>
    </div>
  </DisplayContainer>
</template>

<script>
import TextLink from '@/components/elements/TextLink.vue';
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import DataDisplay from '@/components/elements/DataDisplay.vue';

export default {
  name: 'CustomerDetails',
  components: { TextLink, DisplayContainer, DataDisplay },
  props: {
    data: {
      type: Object,
      required: true
    },
    title: {
      // Equivalent to label
      type: String,
      required: true
    },
    parseSalutation: {
      // Legacy input
      type: Boolean,
      required: false,
      default: false
    },
    small: {
      type: Boolean,
      required: false,
      default: false
    },
    customerId: {
      // deprecated. Data should contain the field customerId. This customerId will generate no link to otrs. The one in data WILL
      type: Number,
      required: false,
      default: 0
    }
  },
  methods: {
    getFormattedSalutation: function () {
      switch (this.data.salutation) {
        case 'MISS':
          return 'Frau';
        case 'MISTER':
          return 'Herr';
        case 'COUPLE':
          return 'Eheleute';
        case 'ALL':
          return '';
      }
    }
  }
};
</script>

<style scoped></style>
