<template>
  <DisplayContainer :label="title">
    <div class="input-row">
      <DataDisplay label="Vertragsnummer">
        <div v-if="data && data.contractId && data.contractId.value">
          {{ data.contractId.value }}
        </div>
      </DataDisplay>
      <DataDisplay label="bestätigt am">
        <div
          v-if="
            data.serviceOrderConfirmationDate &&
              data.serviceOrderConfirmationDate.value
          "
        >
          {{
            new Date(
              data.serviceOrderConfirmationDate.value
            ).toLocaleDateString('de-DE', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            })
          }}
        </div>
      </DataDisplay>
    </div>
  </DisplayContainer>
</template>

<script>
import TextLink from '@/components/elements/TextLink.vue';
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import DataDisplay from '@/components/elements/DataDisplay.vue';

export default {
  name: 'ContractDetails',
  components: { TextLink, DisplayContainer, DataDisplay },
  props: {
    data: {
      type: Object,
      required: true
    },
    title: {
      // Equivalent to label
      type: String,
      required: true
    },
    parseSalutation: {
      // Legacy input
      type: Boolean,
      required: false,
      default: false
    },
    small: {
      type: Boolean,
      required: false,
      default: false
    },
    customerId: {
      // deprecated. Data should contain the field customerId. This customerId will generate no link to otrs. The one in data WILL
      type: Number,
      required: false,
      default: 0
    }
  },
  methods: {
    getFormattedSalutation: function () {
      switch (this.data.salutation) {
        case 'MISS':
          return 'Frau';
        case 'MISTER':
          return 'Herr';
        case 'COUPLE':
          return 'Eheleute';
        case 'ALL':
          return '';
      }
    }
  }
};
</script>

<style scoped></style>
