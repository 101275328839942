var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.documentTableHeaders,"items":_vm.products,"items-per-page":-1,"no-data-text":"Produkte wurden noch nicht geladen.","dense":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.dateOfDeactivation",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({class:_vm.productStatusToStatusClassMappings[item.productStatus]},'div',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.mapProductStatusToStatusMessage( item.productStatus, item.dateOfActivation, item.dateOfDeactivation ))+" ")])])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [(item.description === null || item.description === '')?_c('div',[_vm._v(" - ")]):_c('div',[_vm._v(" "+_vm._s(item.description)+" ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }