var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"group"},[_c('div',[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"dataWrapper"},[_c('div',[_c('div',{staticClass:"label"},[_vm._v(" Kundennummer ")]),_c('div',{staticClass:"input-row-max-content-left"},[_c('div',[_c('TextLink',{attrs:{"linkType":"otrs","linkObject":_vm.data.customerId}},[_vm._v(" "+_vm._s(_vm.data.customerId !== null ? _vm.data.customerId : '-')+" ")])],1)])]),_c('div',[_c('v-select',{attrs:{"label":"Anrede *","items":_vm.salutations,"value":_vm.currentData.salutation,"item-value":function (item) { return item.text; },"placeholder":"Anrede auswählen...","persistent-placeholder":"","required":"","disabled":_vm.disabled},on:{"input":function($event){return _vm.updateData('salutation', $event)}}})],1),_c('div',[_c('v-text-field',{ref:"firstName",attrs:{"value":_vm.currentData.firstName,"label":"Vorname *","rules":[function (val) { return !!val || 'Es muss ein Vorname angegeben sein!'; }],"persistent-placeholder":"","required":"","disabled":_vm.disabled},on:{"input":function($event){return _vm.updateData('firstName', $event)}}})],1),_c('div',[_c('v-text-field',{ref:"lastName",attrs:{"value":_vm.currentData.lastName,"label":"Nachname *","rules":[function (val) { return !!val || 'Es muss ein Nachname angegeben sein!'; }],"persistent-placeholder":"","required":"","disabled":_vm.disabled},on:{"input":function($event){return _vm.updateData('lastName', $event)}}})],1),(_vm.withPhoneNumbers)?_c('div',[_c('v-text-field',{ref:"phone",attrs:{"value":_vm.currentData.phone,"label":"Telefonnummer *","rules":[
          function (val) { return !!val || 'Es muss eine Telefonnummer angegeben sein!'; }
        ],"persistent-placeholder":"","required":"","disabled":_vm.disabled},on:{"input":function($event){return _vm.updateData('phone', $event)}}})],1):_vm._e(),(_vm.withPhoneNumbers)?_c('div',[_c('v-text-field',{ref:"mobile",attrs:{"value":_vm.currentData.mobile,"label":"Handynummer","persistent-placeholder":"","disabled":_vm.disabled},on:{"input":function($event){return _vm.updateData('mobile', $event)}}})],1):_vm._e(),(_vm.withEmail)?_c('div',[_c('v-text-field',{ref:"email",attrs:{"value":_vm.currentData.email,"label":"E-Mail *","persistent-placeholder":"","disabled":_vm.disabled,"rules":[
          function (val) { return !!val || 'Es muss eine E-Mail Adresse angegeben sein!'; }
        ]},on:{"input":function($event){return _vm.updateData('email', $event)}}})],1):_vm._e(),_c('div',[_c('v-text-field',{ref:"street",attrs:{"value":_vm.currentData.street,"label":"Straße *","rules":[function (val) { return !!val || 'Es muss eine Straße angegeben sein!'; }],"persistent-placeholder":"","required":"","disabled":_vm.disabled},on:{"input":function($event){return _vm.updateData('street', $event)}}})],1),_c('div',[_c('v-text-field',{ref:"houseNumberFull",attrs:{"value":_vm.houseNumberFull,"label":"Hausnummer *","rules":[function (val) { return !!val || 'Es muss eine Hausnummer angegeben sein!'; }],"persistent-placeholder":"","required":"","disabled":_vm.disabled},on:{"input":function($event){return _vm.updateHouseNumber($event)}}})],1),_c('div',[_c('v-text-field',{ref:"zipCode",attrs:{"value":_vm.currentData.zipCode,"label":"PLZ *","rules":[
          function (val) { return !!val || 'Es muss eine PLZ angegeben sein!'; },
          function (val) { return (!!val && val.length === 5) || 'Die PLZ muss 5 Zeichen lang sein'; }
        ],"persistent-placeholder":"","required":"","disabled":_vm.disabled},on:{"input":function($event){return _vm.updateData('zipCode', $event)}}})],1),_c('div',[_c('v-text-field',{ref:"city",attrs:{"value":_vm.currentData.city,"label":"Ort *","rules":[function (val) { return !!val || 'Es muss ein Ort angegeben sein!'; }],"persistent-placeholder":"","required":"","disabled":_vm.disabled},on:{"input":function($event){return _vm.updateData('city', $event)}}})],1),_c('div',[_c('DatePicker',{attrs:{"value":_vm.data.birthdate,"minDate":"1900-01-01","maxDate":_vm.currentDate,"label":"Geburtsdatum","disabled":_vm.disabled},on:{"input":function($event){return _vm.updateData('birthdate', $event)}}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }