import { render, staticRenderFns } from "./CarrierSelectionVuetify.vue?vue&type=template&id=edfd9270&scoped=true&"
import script from "./CarrierSelectionVuetify.vue?vue&type=script&lang=js&"
export * from "./CarrierSelectionVuetify.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "edfd9270",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAutocomplete,VIcon,VTooltip})
