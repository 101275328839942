<template>
  <v-row>
    <v-col class="mr-4">
      <v-text-field
        ref="firstName"
        v-model="address.firstName"
        :maxlength="maxLength.name + 1"
        :error-messages="message.firstName"
        :disabled="disabled"
        label="Vorname *"
        :dense="dense"
        @input="onChangeFirstName($event)"
      ></v-text-field>
      <v-text-field
        ref="street"
        v-model="address.street"
        :maxlength="maxLength.street + 1"
        :error-messages="message.street"
        :disabled="disabled"
        label="Strasse *"
        :dense="dense"
        @input="onChangeStreet($event)"
      ></v-text-field>
      <v-text-field
        ref="zipCode"
        v-model="address.zipCode"
        :maxlength="maxLength.zipCode + 1"
        :error-messages="message.zipCode"
        :disabled="disabled"
        label="PLZ *"
        :dense="dense"
        @input="onChangeZipCode($event)"
      ></v-text-field>
    </v-col>
    <v-col>
      <v-text-field
        ref="lastName"
        v-model="address.lastName"
        :maxlength="maxLength.name + 1"
        :error-messages="message.lastName"
        :disabled="disabled"
        label="Nachname *"
        :dense="dense"
        @input="onChangeLastName($event)"
      ></v-text-field>
      <v-col>
        <v-row>
          <v-text-field
            ref="houseNumber"
            v-model="address.houseNumber"
            :maxlength="maxLength.houseNumber + 1"
            :error-messages="message.houseNumber"
            :disabled="disabled"
            label="Hausnummer *"
            :dense="dense"
            class="mr-4"
            @input="onChangeHouseNumber($event)"
          ></v-text-field>
          <v-text-field
            ref="houseNumberSupplement"
            v-model="address.houseNumberSupplement"
            :maxlength="maxLength.houseNumber + 1"
            :error-messages="message.houseNumberSupplement"
            :disabled="disabled"
            label="Zusatz"
            :dense="dense"
            @input="onChangeHouseSupplement($event)"
          ></v-text-field>
        </v-row>
      </v-col>
      <v-text-field
        ref="city"
        v-model="address.city"
        :maxlength="maxLength.city + 1"
        :error-messages="message.city"
        :disabled="disabled"
        label="Ort *"
        :dense="dense"
        @input="onChangeCity($event)"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import DisplayContainer from '@/components/elements/DisplayContainer.vue';

export default {
  name: 'AddressLabelEditor',
  components: {
    DisplayContainer
  },
  props: {
    address: { type: Object, required: true },
    maxLength: { type: Object, required: true },
    disabled: { type: Boolean, required: true },
    dense: { type: Boolean, required: true, default: false }
  },
  data() {
    return {
      message: {
        firstName: null,
        lastName: null,
        street: null,
        houseNumber: null,
        houseNumberSupplement: null,
        zipCode: null,
        city: null
      },
      template: {
        name: (n) => `Zusammen max. ${n} Zeichen`,
        street: (n) => `Max. ${n} Zeichen`,
        houseNumber: (n) => `Zusammen max. ${n} Zeichen`,
        zipCode: (n) => `Max. ${n} Zeichen`,
        city: (n) => `Max. ${n} Zeichen`,
        required: 'Wert erforderlich'
      }
    };
  },
  mounted() {
    // templates
    this.template.name = [this.maxLength.name].map(this.template.name);
    this.template.street = [this.maxLength.street].map(this.template.street);
    this.template.houseNumber = [this.maxLength.houseNumber].map(
      this.template.houseNumber
    );
    this.template.zipCode = [this.maxLength.zipCode].map(this.template.zipCode);
    this.template.city = [this.maxLength.city].map(this.template.city);
    // first + last name
    var length =
      this.length(this.address.firstName) + this.length(this.address.lastName);
    this.message.firstName =
      length > this.maxLength.name ? this.template.name : null;
    this.message.lastName =
      length > this.maxLength.name ? this.template.name : null;
    // street
    this.message.street =
      this.length(this.address.street) > this.maxLength.street
        ? this.template.street
        : null;
    // house number + supplement
    length =
      this.length(this.address.houseNumber) +
      this.length(this.address.houseNumberSupplement);
    this.message.houseNumber =
      length > this.maxLength.houseNumber ? this.template.houseNumber : null;
    this.message.houseNumberSupplement =
      length > this.maxLength.houseNumber ? this.template.houseNumber : null;
    // zip
    this.message.zipCode =
      this.length(this.address.zipCode) > this.maxLength.zipCode
        ? this.template.zipCode
        : null;
    // city
    this.message.city =
      this.length(this.address.city) > this.maxLength.city
        ? this.template.city
        : null;
    //
    this.emitOnChange();
  },
  methods: {
    length(value) {
      return value ? value.length : 0;
    },
    emitOnChange() {
      const hasError = Object.values(this.message).some((msg) => msg !== null);
      this.$emit('onChange', hasError, this.address);
    },
    onChangeFirstName(value) {
      this.address.firstName = value;
      if (value) {
        const length =
          this.length(value) + this.length(this.$refs.lastName.value);
        this.message.firstName =
          length > this.maxLength.name ? this.template.name : null;
        this.message.lastName =
          length > this.maxLength.name ? this.template.name : null;

        this.$refs.lastName.validate();
      } else {
        this.message.firstName = this.template.required;
      }
      this.$refs.firstName.validate();
      this.emitOnChange();
    },
    onChangeLastName(value) {
      this.address.lastName = value;
      if (value) {
        const length =
          this.length(value) + this.length(this.$refs.firstName.value);
        this.message.firstName =
          length > this.maxLength.name ? this.template.name : null;
        this.message.lastName =
          length > this.maxLength.name ? this.template.name : null;
        this.$refs.firstName.validate();
      } else {
        this.message.lastName = this.template.required;
      }
      this.$refs.lastName.validate();
      this.emitOnChange();
    },
    onChangeStreet(value) {
      this.address.street = value;
      if (value) {
        this.message.street =
          this.length(value) > this.maxLength.street
            ? this.template.street
            : null;
      } else {
        this.message.street = this.template.required;
      }
      this.$refs.street.validate();
      this.emitOnChange();
    },
    onChangeHouseNumber(value) {
      this.address.houseNumber = value;
      if (value) {
        const length =
          this.length(value) +
          this.length(this.$refs.houseNumberSupplement.value);
        this.message.houseNumber =
          length > this.maxLength.houseNumber
            ? this.template.houseNumber
            : null;
        this.message.houseNumberSupplement =
          length > this.maxLength.houseNumber
            ? this.template.houseNumber
            : null;
        this.$refs.houseNumberSupplement.validate();
      } else {
        this.message.houseNumber = this.template.required;
      }
      this.$refs.houseNumber.validate();
      this.emitOnChange();
    },
    onChangeHouseSupplement(value) {
      this.address.houseNumberSupplement = value;
      if (value) {
        const length =
          this.length(value) + this.length(this.$refs.houseNumber.value);
        this.message.houseNumber =
          length > this.maxLength.houseNumber
            ? this.template.houseNumber
            : null;
        this.message.houseNumberSupplement =
          length > this.maxLength.houseNumber
            ? this.template.houseNumber
            : null;
        this.$refs.houseNumber.validate();
        this.$refs.houseNumberSupplement.validate();
      }
      this.emitOnChange();
    },
    onChangeZipCode(value) {
      this.address.zipCode = value;
      if (value) {
        this.message.zipCode =
          this.length(value) > this.maxLength.zipCode
            ? this.template.zipCode
            : null;
      } else {
        this.message.zipCode = this.template.required;
      }
      this.$refs.zipCode.validate();
      this.emitOnChange();
    },
    onChangeCity(value) {
      this.address.city = value;
      if (value) {
        this.message.city =
          this.length(value) > this.maxLength.city ? this.template.city : null;
      } else {
        this.message.city = this.template.required;
      }
      this.$refs.city.validate();
      this.emitOnChange();
    }
  }
};
</script>

<style lang="scss" scoped>
.v-text-field ::v-deep input {
  font-size: 0.9em;
}
</style>
