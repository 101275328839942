<template>
  <div class="input-row">
    <DataDisplay label="Vertragsnummer">
      <div v-if="contractId">
        {{ contractId }}
      </div>
    </DataDisplay>
    <DataDisplay label="Kundennummer">
      <div v-if="customerId">
        {{ customerId }}
      </div>
    </DataDisplay>
    <NameDisplay :addressDto="address" />
    <AddressDisplay :addressDto="address" />
    <DateDisplay label="Aktivierungsdatum" :date="activationDateOfContract" />
    <DateDisplay
      label="Deaktivierungsdatum"
      :date="deactivationDateOfContract"
    />
  </div>
</template>

<script>
import DataDisplay from '@/components/elements/DataDisplay.vue';
import NameDisplay from '@/components/dataDisplays/NameDisplay.vue';
import DateDisplay from '@/components/dataDisplays/DateDisplay.vue';

import AddressDisplay from '@/components/dataDisplays/AddressDisplay.vue';
import TimeUtility from '@/util/TimeUtility.js';
export default {
  components: {
    DataDisplay,
    NameDisplay,
    AddressDisplay,
    DateDisplay
  },
  props: {
    contractId: { type: Number, required: true },
    customerId: { type: Number, required: true },
    activationDateOfContract: { type: String, required: false, default: null },
    deactivationDateOfContract: {
      type: String,
      required: false,
      default: null
    },
    address: {
      type: Object,
      required: true
    }
  },
  methods: {
    formatDate(date) {
      return TimeUtility.formatLocalDate(date);
    }
  }
};
</script>

<style></style>
