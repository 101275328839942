<template>
  <div class="group">
    <div>{{ title }}</div>
    <div class="dataWrapper">
      <div>
        <div class="label">
          Kundennummer
        </div>
        <div class="input-row-max-content-left">
          <div>
            <TextLink linkType="otrs" :linkObject="data.customerId">
              {{ data.customerId !== null ? data.customerId : '-' }}
            </TextLink>
          </div>
        </div>
      </div>

      <div>
        <v-select
          label="Anrede *"
          :items="salutations"
          :value="currentData.salutation"
          :item-value="(item) => item.text"
          placeholder="Anrede auswählen..."
          persistent-placeholder
          required
          :disabled="disabled"
          @input="updateData('salutation', $event)"
        />
      </div>

      <div>
        <v-text-field
          ref="firstName"
          :value="currentData.firstName"
          label="Vorname *"
          :rules="[(val) => !!val || 'Es muss ein Vorname angegeben sein!']"
          persistent-placeholder
          required
          :disabled="disabled"
          @input="updateData('firstName', $event)"
        />
      </div>

      <div>
        <v-text-field
          ref="lastName"
          :value="currentData.lastName"
          label="Nachname *"
          :rules="[(val) => !!val || 'Es muss ein Nachname angegeben sein!']"
          persistent-placeholder
          required
          :disabled="disabled"
          @input="updateData('lastName', $event)"
        />
      </div>

      <div v-if="withPhoneNumbers">
        <v-text-field
          ref="phone"
          :value="currentData.phone"
          label="Telefonnummer *"
          :rules="[
            (val) => !!val || 'Es muss eine Telefonnummer angegeben sein!'
          ]"
          persistent-placeholder
          required
          :disabled="disabled"
          @input="updateData('phone', $event)"
        />
      </div>

      <div v-if="withPhoneNumbers">
        <v-text-field
          ref="mobile"
          :value="currentData.mobile"
          label="Handynummer"
          persistent-placeholder
          :disabled="disabled"
          @input="updateData('mobile', $event)"
        />
      </div>

      <div v-if="withEmail">
        <v-text-field
          ref="email"
          :value="currentData.email"
          label="E-Mail *"
          persistent-placeholder
          :disabled="disabled"
          :rules="[
            (val) => !!val || 'Es muss eine E-Mail Adresse angegeben sein!'
          ]"
          @input="updateData('email', $event)"
        />
      </div>

      <div>
        <v-text-field
          ref="street"
          :value="currentData.street"
          label="Straße *"
          :rules="[(val) => !!val || 'Es muss eine Straße angegeben sein!']"
          persistent-placeholder
          required
          :disabled="disabled"
          @input="updateData('street', $event)"
        />
      </div>

      <div>
        <v-text-field
          ref="houseNumberFull"
          :value="houseNumberFull"
          label="Hausnummer *"
          :rules="[(val) => !!val || 'Es muss eine Hausnummer angegeben sein!']"
          persistent-placeholder
          required
          :disabled="disabled"
          @input="updateHouseNumber($event)"
        />
      </div>

      <div>
        <v-text-field
          ref="zipCode"
          :value="currentData.zipCode"
          label="PLZ *"
          :rules="[
            (val) => !!val || 'Es muss eine PLZ angegeben sein!',
            (val) =>
              (!!val && val.length === 5) || 'Die PLZ muss 5 Zeichen lang sein'
          ]"
          persistent-placeholder
          required
          :disabled="disabled"
          @input="updateData('zipCode', $event)"
        />
      </div>

      <div>
        <v-text-field
          ref="city"
          :value="currentData.city"
          label="Ort *"
          :rules="[(val) => !!val || 'Es muss ein Ort angegeben sein!']"
          persistent-placeholder
          required
          :disabled="disabled"
          @input="updateData('city', $event)"
        />
      </div>

      <div>
        <DatePicker
          :value="data.birthdate"
          minDate="1900-01-01"
          :maxDate="currentDate"
          label="Geburtsdatum"
          :disabled="disabled"
          @input="updateData('birthdate', $event)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import DatePicker from './elements/DatePicker.vue';
import TextLink from '@/components/elements/TextLink.vue';

export default {
  name: 'CustomerDataEdit',
  components: {
    DatePicker,
    TextLink
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    withPhoneNumbers: {
      type: Boolean,
      default: false,
      required: false
    },
    withEmail: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  computed: {
    currentData() {
      return { ...this.data };
    },
    currentDate() {
      const today = new Date();
      return (
        today.getFullYear() +
        '-' +
        String(today.getMonth() + 1).padStart(2, '0') +
        '-' +
        String(today.getDate()).padStart(2, '0')
      );
    },
    salutations() {
      return Object.values(this.salutation).filter(
        (salutation) => salutation.value !== this.salutation.ALL.value
      );
    },
    houseNumberFull() {
      if (this.data.houseNumberSupplement !== null) {
        return `${this.data.houseNumber}${this.data.houseNumberSupplement}`;
      } else {
        return this.data.houseNumber;
      }
    }
  },
  created() {
    this.salutation = {
      MISS: {
        value: 'MISS',
        text: 'Frau'
      },
      MISTER: {
        value: 'MISTER',
        text: 'Herr'
      },
      COUPLE: {
        value: 'COUPLE',
        text: 'Eheleute'
      },
      ALL: {
        value: 'ALL',
        text: 'ALL'
      }
    };
  },
  mounted: function () {
    this.validateInputs();
  },
  methods: {
    updateData(field, newValue) {
      const updatedData = { ...this.currentData };
      updatedData[field] = newValue;
      this.$emit('onDataChange', updatedData);
      this.validateInputs();
    },
    updateHouseNumber(newValue) {
      const updatedData = { ...this.currentData };
      const houseNumber = newValue.match(/^[0-9]*/)[0];
      updatedData.houseNumber = houseNumber;
      updatedData.houseNumberSupplement = newValue.substring(
        houseNumber.length
      );
      this.$emit('onDataChange', updatedData);
      this.validateInputs();
    },
    validateInputs() {
      let allInputsValid = true;
      Object.keys(this.$refs).forEach((ref) => {
        allInputsValid = allInputsValid && this.$refs[ref].validate();
      });
      this.$emit('invalid', !allInputsValid);
    }
  }
};
</script>
<style scoped>
.group > div:nth-child(2) > div {
  margin-bottom: var(--goe-spacing-2);
}

.group > div:nth-child(2) > div:last-child {
  margin-bottom: var(--goe-spacing-0);
}

.dataWrapper {
  display: grid;
  grid-template-columns: calc(50% - var(--goe-spacing-0_5)) calc(
      50% - var(--goe-spacing-0_5)
    );
  grid-gap: var(--goe-spacing-1);
}
</style>
